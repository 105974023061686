import { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Gallery from "./components/pages/Gallery";
import Events from "./components/pages/Events";
import FohsAwards from "./components/pages/FohsAwards";
import FohsPress from "./components/pages/FohsPress";
import FohsChapters from "./components/pages/FohsChapters";
import EventView from "./components/pages/EventView";
import Contact from "./components/pages/Contact";
import Tour_Trail from "./components/pages/Tour&trail";
import Projects from "./components/pages/Projects";
import Workshops from "./components/pages/Workshops";
import Awareness from "./components/pages/Awareness";
import UpcomingEvents from "./components/pages/UpcomingEvents";
import Gac from "./components/pages/Gac";
import Navbar from "./components/inc/Navbar";
import Footer from "./components/inc/Footer";
import Pandyas from "./components/pages/Pandyas";
import Pallavas from "./components/pages/Pallavas";
import ThenPandyas from "./components/pages/ThenPandyas";
import Maintenance from "./components/pages/Maintenance";
import Noevents from "./components/pages/noevents";

function App() {
  const [loader, setLoder] = useState(false);
  const loderFunction = (value) => {
    setLoder(value);
  };
  return (
    <Router>
      <Navbar />
      <div className="container mt-6">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/team" element={<About />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/pandyas"
            // element={<Pandyas loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>          
          <Route path="/pallavas"
            // element={<Pallavas loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>         
          <Route path="/thenpandyas"
            // element={<ThenPandyas loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>
          <Route path="/awards"
            // element={<FohsAwards loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>
          <Route path="/press"
            element={<FohsPress loderFunction={loderFunction} />}
          ></Route>
          <Route path="/timeline/:year_param" element={<Events loderFunction={loderFunction} />}></Route>
          <Route path="/timeline"
            // element={<Events loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>
          <Route path="/tour&trail" element={<Tour_Trail loderFunction={loderFunction} />}></Route>
          <Route path="/projects"
            //element={<Projects loderFunction={loderFunction} />}
	          element={<Maintenance/>}
          ></Route>
          <Route path="/workshops"
            // element={<Workshops loderFunction={loderFunction} />}
            element={<Maintenance/>}
          ></Route>
          <Route path="/awareness" element={<Awareness loderFunction={loderFunction} />}></Route>
          <Route path="/upcoming-events" element={<UpcomingEvents loderFunction={loderFunction} />}></Route>
          <Route path="/event/:slug" element={<EventView loderFunction={loderFunction} />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/ga&c" element={<Gac />}></Route>
          <Route path="/maintenance" element={<Maintenance />}></Route>
          <Route path="/noevents" element={<Noevents />}></Route>
        </Routes>
        {loader && (
          <div className="loader">
            <BeatLoader color="#292225" speedMultiplier={1} />
          </div>
        )}
      </div>
      <Footer />
    </Router>
  );
}

export default App;
