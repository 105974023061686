import React from "react";
import { useNavigate } from "react-router-dom";
import maintain from "../images/page/home/noevents.png";
import defaultpng from "../images/icons/default.jpg";

function Noevents(props) {
  let maintain_data = [];
  let maintain_item = {};
  maintain_item.image = maintain;
  maintain_data.push(maintain_item);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); 
  };
  return (
    <section className="mt-5">
      <button class="btn btn-secondary" onClick={handleBack}>Back</button>
      <div className="band">
        {maintain_data.map((item, ind) => {
          return (
            <div className={`item-${ind}`} key={ind}>
              <a className="my-card" id="maintain">
                <div className="thumb"
                  style={{
                    backgroundImage: `url(${
                      item?.image != null || "" ? item?.image : defaultpng
                    })`,
                  }}
                >                    
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
}
export default Noevents;
